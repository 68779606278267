import { createSlice } from '@reduxjs/toolkit';
import api from '../../AxiosHelper';
import { MIDDLE_CREDENTIAL, storeAssigned } from '../../Methods/normalMethods';


const AllProductSlice = createSlice({
  name: "allProductPage",
  initialState: {
    data: [],
    creamData: [],
    paginationData: [],
    distributorProducts: [],
    retailProduct: [],
    prefferedProducts: [],
    totalCount: 0,
    error: '',
    isLoading: false,
    colors: [],
  },
  reducers: {
    setAllProducts: (state, action) => {
      if (action.payload?.section === "distributorProducts") {
        state['distributorProducts'] = action.payload?.res;
      } else if (action.payload?.section === "retailProduct") {
        state['retailProduct'] = action.payload?.res;
      } else if (action.payload?.section === "prefferedProducts") {
        state['prefferedProducts'] = action.payload?.res;
      } else {
        state['data'] = action.payload?.res;
      }
    },
    setIsLoading: (state, action) => {
      state['isLoading'] = action.payload;
    },
    setPaginationData: (state, action) => {
      state['paginationData'] = action.payload;
      state['totalCount'] = action.payload?.totalRecords
    },
    setError: (state, action) => {
      state['error'] = 'somthing went wrong please refresh the page.'
    }
  }
});

export const { isLoading, setIsLoading, setAllProducts, setCreamsProducts, setPaginationData, setError } = AllProductSlice.actions;

function _getCurrentCatId(catId, section) {
  const COUNTRYID = { us: 1000, ca: 1000 };

  let id = catId || COUNTRYID[storeAssigned()];
  switch (section) {
    case 'All Products':
      id = 1000;
      break;
  }
  return id;
}

export function _getWebCatId(section) {
  const AllPRODUCTSCOUNTRYID = {
    1001: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1031: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1011: ['jp'],
    1021: ['co'],
    1041: ['mx']
  };

  const ENROLLMENTCOUNTRYID = {
    1007: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1037: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1017: ['jp'],
    1027: ['co'],
    1047: ['mx']
  };

  const SKINCARECOUNTRYID = {
    1002: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1032: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1012: ['jp'],
    1022: ['co'],
    1042: ['mx']
  };

  const BODYCARECOUNTRYID = {
    1003: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1033: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1013: ['jp'],
    1023: ['co'],
    1043: ['mx']
  };

  const HAIRCARECOUNTRYID = {
    1004: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1034: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1014: ['jp'],
    1024: ['co'],
    1044: ['mx']
  };

  const NUTRITIONCOUNTRYID = {
    1005: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1035: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1015: ['jp'],
    1025: ['co'],
    1045: ['mx']
  };

  const STEP1ENROLLMENTCOUNTRYID = {
    1008: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1038: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'lv', 'lu', 'pf'],
    1018: ['jp'],
    1028: ['co'],
    1048: ['mx']
  };
  const STEP2ENROLLMENTCOUNTRYID = {
    1009: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1039: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'lv', 'lu', 'pf'],
    1019: ['jp'],
    1029: ['co'],
    1049: ['mx']
  };
  const STEP3ENROLLMENTCOUNTRYID = {
    1050: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1053: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'lv', 'lu', 'pf'],
    1051: ['jp'],
    1052: ['co'],
    1054: ['mx']
  };
  const CLUBVIAGO = {
    1006: ['us', 'au', 'br', 'bs', 'ca', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'tw'],
    1036: ['eu', 'at', 'be', 'bg', 'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'pf'],
    1016: ['jp'],
    1026: ['co'],
    1046: ['mx']
  };

  const getId = (countryCode, idMap) => {
    for (const [id, countries] of Object.entries(idMap)) {
      if (countries.includes(countryCode)) {
        return id;
      }
    }
    return 1000;
  }
  let id = 1000;

  switch (section) {
    case 'All Products':
      id = getId(storeAssigned(), AllPRODUCTSCOUNTRYID);
      break;
    case 'Enrollment':
      id = getId(storeAssigned(), ENROLLMENTCOUNTRYID);
      break;
    case 'Step1Enrollment':
      id = getId(storeAssigned(), STEP1ENROLLMENTCOUNTRYID);
      break;
    case 'Step2Enrollment':
      id = getId(storeAssigned(), STEP2ENROLLMENTCOUNTRYID);
      break;
    case 'Step3Enrollment':
      id = getId(storeAssigned(), STEP3ENROLLMENTCOUNTRYID);
      break;
    case 'Skincare':
      id = getId(storeAssigned(), SKINCARECOUNTRYID);
      break;
    case 'Bodycare':
      id = getId(storeAssigned(), BODYCARECOUNTRYID);
      break;
    case 'Haircare':
      id = getId(storeAssigned(), HAIRCARECOUNTRYID);
      break;
    case 'Nutrition':
      id = getId(storeAssigned(), NUTRITIONCOUNTRYID);
      break;
    case 'Club Viago':
      id = getId(storeAssigned(), CLUBVIAGO);
      break;
    default:
      id = 1000;
      break;
  }

  return id;
}


export const getAllProductApi = (section = "All Products", callback, catId = null) => dispatch => {
  dispatch(setIsLoading(true));
  const id = _getCurrentCatId(catId, section);
  api().setHeaders(MIDDLE_CREDENTIAL).post().data({
    method: "GET",
    endpoint: `/Crm/Items/WebCategory/${id}?hideFromWeb=eq:false&OrderByColumn=widthMetric&OrderByMethod=desc`
  }).success((res) => {
    if (res?.data) {
      dispatch(setAllProducts({ res: res?.data, section }));
      if (callback) {
        callback(res?.data);
      }
      dispatch(setIsLoading(false));
    }
  }).error((err) => {
    dispatch(setIsLoading(false));
  }).send()
};

export const getAllSingleProduct = (section = "All Products", productId = 0, callback, catId = null) => dispatch => {
  dispatch(setIsLoading(true));
  const id = _getCurrentCatId(catId, section);

  api().setHeaders(MIDDLE_CREDENTIAL).post().data({
    method: "GET",
    endpoint: `/Crm/Items?hideFromWeb=eq:false&itemId=eq:${productId}`
  }).success((res) => {
    if (res?.data) {
      if (callback) {
        callback(res?.data);
      }
      dispatch(setIsLoading(false));
    }
  }).error((err) => {
    dispatch(setError(err))
    // dispatch(getAllSingleProduct(section, productId, callback, catId))
    window.location.reload()
    dispatch(setIsLoading(false));
  }).send()
};

export const getProductsByTitle = (section = "All Products", title = '', callback, catId = null) => dispatch => {
  dispatch(setIsLoading(true));
  const id = _getCurrentCatId(catId, section);

  api().setHeaders(MIDDLE_CREDENTIAL).post().data({
    method: "GET",
    endpoint: `/Crm/Items/WebCategory/${id}?hideFromWeb=eq:false&title=eq:${title}`
  }).success((res) => {
    if (res?.data) {
      if (callback) {
        callback(res?.data);
      }
      dispatch(setIsLoading(false));
    }
  }).error((err) => {
    dispatch(setError(err))
    // dispatch(getAllSingleProduct(section, productId, callback, catId))
    window.location.reload()
    dispatch(setIsLoading(false));
  }).send()
};

export const getAllProductApiPagination = (currentpage, id = 1000, callback = null, searchByTitle = "", sortOrder, rankUpgrad) => dispatch => {
  dispatch(setIsLoading(true));
  let url;
  if (rankUpgrad === 'main_enrollment') {
    url = `/Crm/Items/WebCategory/${id}`;
  } else {
    // url = `/Crm/Items/WebCategory/${id || 1000}?hideFromWeb=eq:false&limit=9&Offset=${currentpage}&OrderByColumn=title&itemCode=eq:`/${ storeAssigned }`&OrderByMethod=${sortOrder || 'sort'}${searchByTitle ? `&title=cn:${encodeURI(searchByTitle)}` : ''}`;
    url = `/Crm/Items/WebCategory/${id || 1000}?hideFromWeb=eq:false&limit=9&Offset=${currentpage}&OrderByColumn=title&OrderByMethod=${sortOrder || 'sort'}${searchByTitle ? `&title=cn:${encodeURI(searchByTitle)}` : ''}`;


  }
  api()
    .setHeaders(MIDDLE_CREDENTIAL)
    .post()
    .data({
      method: "GET",
      endpoint: url,
    })
    .success((res) => {
      if (res?.data) {
        dispatch(setPaginationData(res));
        if (callback) {
          // Pass totalRecords along with the data
          callback(res?.data, res?.totalRecords);
        }
        dispatch(setIsLoading(false));
      }
    })
    .error((err) => {
      dispatch(setIsLoading(false));
    })
    .send();
};

export default AllProductSlice.reducer;
